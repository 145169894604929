import React, { useState } from 'react';

import { addUserToEmailSubscription, CustomFieldType } from 'utils/functions/addUserToEmailSubscription';
import * as Yup from 'yup';

import { Button } from 'components/Shared/Inputs/Button';
import { Form } from 'components/Shared/Forms/Form';
import { InputField } from 'components/Shared/UI/InputField';
import { Select } from 'components/Shared/Inputs/Select';

import { states } from '../../consts/states';

export function SubscribeSection() {
  const [success, setSuccess] = useState(false);
  const [customFields, setCustomFields] = useState<CustomFieldType>();

  function handleSubmit({ firstname, lastname, email }) {
    setSuccess(addUserToEmailSubscription(email, `${firstname} ${lastname}`, customFields));
  }

  function handleSelectState(stateValue) {
    const customField = {
      name: 'state',
      value: stateValue,
    };

    setCustomFields(customField);
  }

  return (
    <div className="l-full-width h-background--color-brand-secondary l-position--relative h-with-2x-large-y-padding">
      <img className="l-position--centred" src="/images/ring-primary.svg" alt="" />
      <div className="l-container l-container--medium l-responsive-grid l-flex--center h-with-4x-large-y-padding l-position--relative">
        <div className="l-responsive-grid__item--4-col">
          <h2 style={{ marginBottom: '0.25rem' }}>Stay in the loop</h2>
          <p>OurFutures newsletter</p>
        </div>
        <div className="l-responsive-grid__item--8-col">
          {success ? (
            <p>You have been added to the OurFutures subscription list</p>
          ) : (
            <Form
              id="subscribe"
              initialValues={{
                firstname: '',
                lastname: '',
                email: '',
              }}
              validationSchema={Yup.object().shape({
                firstname: Yup.string().label('First name').required('is required'),
                lastname: Yup.string().label('Last name').required('is required'),
                email: Yup.string().label('Email').email().required('is required'),
              })}
              onSubmit={handleSubmit}
            >
              <div className="l-flex l-flex--mobile-column l-full-width">
                <InputField
                  className="l-flex--1"
                  name="firstname"
                  type="text"
                  label="First name"
                  autoComplete="given-name"
                  required
                  orientation="row"
                />
                <InputField
                  className="l-flex--1"
                  name="lastname"
                  type="text"
                  label="Last name"
                  autoComplete="family-name"
                  required
                  orientation="row"
                />
              </div>

              <div className="l-flex l-flex--mobile-column" style={{ gap: '1rem', alignItems: 'flex-start' }}>
                <InputField name="email" type="email" label="Email address" autoComplete="email" required />
                <Select
                  name="state_list"
                  buttonClassName="l-full-width h-no-border subscribe-state-select"
                  onChange={handleSelectState}
                  isDisabled={states.length === 0}
                  selectTitle="Select state"
                  options={states.map((option) => {
                    return { value: option.value, content: option.label };
                  })}
                  defaultValue=""
                  color="primary"
                />
              </div>
              <Button
                type="submit"
                className="c-input l-flex__item--row l-width--auto h-with-y-margin h-border c-button--outline"
                color="primary"
              >
                Subscribe
              </Button>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
}
