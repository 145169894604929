import { useEffect, useState } from 'react';

import cx from 'classnames';
import { useNotifications } from 'contexts/Notifications/Context';

import { Button } from 'components/Shared/Inputs/Button';

export function Reminder() {
  const { reminderNotification, setNotificationSeen } = useNotifications();
  const [hasBeenClosed, setHasBeenClosed] = useState(false);
  const [hasBeenSeen, setHasBeenSeen] = useState(false);

  useEffect(() => {
    if (!reminderNotification || reminderNotification.length === 0 || hasBeenSeen) return;
    setNotificationSeen(reminderNotification[0].guid);
    setHasBeenSeen(true);
  }, [reminderNotification]);

  return (
    <>
      {reminderNotification && reminderNotification.length !== 0 && !hasBeenClosed && (
        <div className={cx('reminder-card--outer')}>
          <div className={cx('reminder-card')}>
            <div>
              <Button
                className={` l-position--relative mr l-position-h-right h-top-sm`}
                color="primary"
                onClick={() => setHasBeenClosed(true)}
              >
                X
              </Button>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: reminderNotification[0]?.content }}
              style={{ height: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            ></div>
          </div>
        </div>
      )}
    </>
  );
}
