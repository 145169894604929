import { Popup } from 'components/Shared/UI/Popup/Popup';
import { FeedbackPopupProps } from './FeedbackPopup.interfaces';
import { gql, useMutation } from '@apollo/client';
import { useQuery } from 'utils/hooks/useQuery';
import { Client } from 'graphql/client';
import { createContext, useEffect, useRef, useState } from 'react';
import { EkardoPage } from 'components/Shared/Ekardo/EkardoPage';
import { useRouter } from 'next/router';
import { ITrackingContext, ManualTrackingProvider } from 'contexts/Tracking/Context';
import { Button } from 'components/Shared/Inputs/Button/Button';
import { useAccessToken } from 'contexts/Authorization/Context';
import { useCookies } from 'react-cookie';
import { useLazyQuery } from 'utils';

const GetUserFlowStep = gql`
  query getUserFlowStep($url: String!, $projectId: String!, $userFlowUrl: String!) {
    userFlowStep {
      getStepByUrl(url: $url, projectId: $projectId, userFlowUrl: $userFlowUrl) {
        id
        contentGroup {
          contentPages {
            id
            url
          }
        }
        isComplete
      }
    }
  }
`;

const GetQuestionAnswersQuery = gql`
  query GetQuestionAnswersQuery($stepTrackingId: Int!) {
    questionAnswer {
      getAnswers(trackingId: $stepTrackingId) {
        id
        contentSnippetQuestionId
        ... on QuestionAnswerCheckboxType {
          selectedAnswers {
            questionResponse {
              id
              value: scoreValue
            }
          }
          __typename
        }
        ... on QuestionAnswerDropDownListType {
          questionResponse {
            id
            value: scoreValue
          }
          __typename
        }
        ... on QuestionAnswerMultiResponseTextType {
          id
          responses {
            value
            __typename
          }
          __typename
        }
        ... on QuestionAnswerNumberType {
          value: numberAnswered
          __typename
        }
        ... on QuestionAnswerRadioType {
          questionResponse {
            id
            value: scoreValue
          }
          __typename
        }
        ... on QuestionAnswerSliderType {
          value
          __typename
        }
      }
    }
  }
`;

const EndStep = gql`
  mutation endStep($trackingId: Int!) {
    userFlowStepTrack {
      endUserFlowStep(trackingId: $trackingId, status: COMPLETED) {
        id
      }
    }
  }
`;

const StartStep = gql`
  mutation startStep($stepId: Int!) {
    userFlowStepTrack {
      startUserFlowStep(stepId: $stepId) {
        id
      }
    }
  }
`;

interface IFeedbackContext {
  questionAnswers?: Record<number, any>;
  setQuestionAnswer: (questionId: number, answer: any) => void;
}

const Context = createContext<IFeedbackContext>({
  questionAnswers: [],
  setQuestionAnswer: () => {},
});

function AnswersProvider({ children, stepTrackingId, client }) {
  const [questionAnswers, setQuestionAnswers] = useState<Record<number, any>>({});

  function setQuestionAnswer(questionId, answer) {
    setQuestionAnswers((currentState) => {
      const temp = { ...currentState };
      temp[questionId] = answer;
      return temp;
    });
  }

  useQuery(GetQuestionAnswersQuery, {
    client,
    variables: {
      stepTrackingId,
    },
    onCompleted(response) {
      const {
        questionAnswer: { getAnswers: answers },
      } = response;

      const updatedQuestionAnswers = answers.reduce((accumulator, answer) => {
        const { contentSnippetQuestionId, questionResponse, selectedAnswers, __typename: typeName, value } = answer;

        let questionAnswer;

        switch (typeName) {
          case 'QuestionAnswerCheckboxType': {
            questionAnswer = selectedAnswers.reduce((memo, current) => Number(memo) + Number(current.questionResponse.value), 0);
            break;
          }
          case 'QuestionAnswerDropDownListType': {
            questionAnswer = questionResponse.value;
            break;
          }
          case 'QuestionAnswerMultiResponseTextType': {
            questionAnswer = answer.responses.map((answerResponse) => answerResponse.value);
            break;
          }
          case 'QuestionAnswerNumberType': {
            questionAnswer = value;
            break;
          }
          case 'QuestionAnswerRadioType': {
            questionAnswer = questionResponse.value;
            break;
          }
          case 'QuestionAnswerSliderType': {
            questionAnswer = value;
            break;
          }
          default: {
            questionAnswer = 0;
          }
        }

        return {
          ...accumulator,
          [contentSnippetQuestionId]: questionAnswer,
        };
      }, {});

      setQuestionAnswers(updatedQuestionAnswers);
    },
  });

  return (
    <Context.Provider
      value={{
        questionAnswers,
        setQuestionAnswer,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const FeedbackPopup = ({ userFlowStepUrl }: FeedbackPopupProps) => {
  const moduleCookieDone = `${userFlowStepUrl}-done`;

  const [trackingId, setTrackingId] = useState(undefined);
  const client = useRef(Client('ekardo'));
  const [pageId, setPageId] = useState(undefined);
  const [pageUrl, setPageUrl] = useState(undefined);
  const [stepId, setStepId] = useState(undefined);

  const { replace } = useRouter();
  const token = useAccessToken();

  const [cookies, setCookie, removeCookie] = useCookies();
  const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState<boolean>(false);

  const [executeGetUserFlowStep] = useLazyQuery(GetUserFlowStep, {
    client: client.current,
    accessToken: token,
    onCompleted: (data) => {
      const {
        userFlowStep: { getStepByUrl },
      } = data;

      if (!getStepByUrl) return;

      const {
        contentGroup: { contentPages },
        isComplete,
      } = getStepByUrl;

      const { url, id } = contentPages[0];

      if (!isComplete) {
        setIsFeedbackPopupOpen(true);
        setPageUrl(url);
        setPageId(id);
        setStepId(getStepByUrl.id);
      } else {
        setCookie(moduleCookieDone, 'true');
      }
    },
  });

  useEffect(() => {
    if (cookies[userFlowStepUrl] && !cookies[moduleCookieDone]) {
      executeGetUserFlowStep({
        variables: {
          url: userFlowStepUrl,
          userFlowUrl: 'feedback',
          projectId: process.env.REACT_APP_PROJECT_ID,
        },
      });
    }
  }, []);

  const [executeEndStep] = useMutation(EndStep, {
    client: client.current,
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: () => {
      setCookie(moduleCookieDone, 'true');
      setIsFeedbackPopupOpen(false);
    },
  });

  const [executeStartStep] = useMutation(StartStep, {
    client: client.current,
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: (data) => {
      const {
        userFlowStepTrack: {
          startUserFlowStep: { id },
        },
      } = data;
      setTrackingId(id);
    },
  });

  const context: ITrackingContext = {
    setIsLoading: () => {},
    completeContentPageTracking: () => {},
    completeUserFlowStepTracking: () => {},
    stepTrackingId: trackingId,
    pageId,
  };

  useEffect(() => {
    if (!stepId) return;

    if (isFeedbackPopupOpen) {
      executeStartStep({
        variables: {
          stepId,
        },
      });
    }
  }, [stepId, isFeedbackPopupOpen]);

  const endUserFlowStep = () => {
    executeEndStep({
      variables: {
        trackingId,
      },
    });
    removeCookie('userFlowStepUrl', { path: '/' });
  };

  return (
    <>
      {isFeedbackPopupOpen && (
        <Popup
          additionalClassNames="large"
          onClickClosePopup={() => setIsFeedbackPopupOpen(false)}
          {...(trackingId && {
            footer: (
              <Button
                className="l-flex__item--row l-position-h-right"
                icon="arrow"
                rotateIcon="270"
                onClick={() => {
                  endUserFlowStep();
                }}
              >
                I don't wish to provide feedback
              </Button>
            ),
          })}
        >
          {trackingId && (
            <>
              <ManualTrackingProvider tracking={context}>
                {() => {
                  return (
                    <AnswersProvider stepTrackingId={trackingId} client={client.current}>
                      <EkardoPage className="c-module-survey" pageUrl={pageUrl} pageId={pageId} userFlowStepTrackId={trackingId} />
                    </AnswersProvider>
                  );
                }}
              </ManualTrackingProvider>
              <div className="l-flex l-flex--justify-end" style={{ marginRight: '1rem' }}>
                <Button
                  className="l-flex__item--row"
                  icon="arrow"
                  rotateIcon="270"
                  onClick={() => {
                    endUserFlowStep();
                  }}
                >
                  Complete
                </Button>
              </div>
            </>
          )}
        </Popup>
      )}
    </>
  );
};
