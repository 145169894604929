export const states = [
  {
    value: 'ACT',
    label: 'ACT',
  },
  {
    value: 'NSW',
    label: 'NSW',
  },
  {
    value: 'NT',
    label: 'NT',
  },
  {
    value: 'QLD',
    label: 'QLD',
  },
  {
    value: 'SA',
    label: 'SA',
  },
  {
    value: 'TAS',
    label: 'TAS',
  },
  {
    value: 'VIC',
    label: 'VIC',
  },
  {
    value: 'WA',
    label: 'WA',
  },
  {
    value: 'N/A (Outside Australia)',
    label: 'N/A (Outside Australia)',
  },
];
