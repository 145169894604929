import { IAddClassProps } from './AddClassPopup.interface';
import { Button } from 'components/Shared/Inputs/Button';
import { Popup } from 'components/Shared/UI/Popup/Popup';
import gql from 'graphql-tag';
import { useMutation } from 'utils/hooks/useMutation';
import { Client } from 'graphql/client';
import { useRef, useState } from 'react';
import { LoadingIcon } from 'components/Spinners/LoadingIcon';
import * as Yup from 'yup';
import { InputField, PopupErrorMessages } from 'components/Shared';
import { Form } from 'components/Shared/Forms/Form';
import { getErrorMessages } from 'utils';

const AddClass = gql`
  mutation addClass($groupId: Int!, $name: String!) {
    unit {
      add(groupId: $groupId, unitName: $name) {
        id
      }
    }
  }
`;

const AddClassPopup = ({ onClose, onCompleted, groupId }: IAddClassProps) => {
  const client = useRef(Client('gelada'));

  const [isProcessCompleted, setIsProcessCompleted] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>();

  function handleError(error) {
    const errorMessages = getErrorMessages(error);
    setErrorMessages(errorMessages);
  }
  const [executeAddClass, { loading: addClassLoading }] = useMutation(AddClass, {
    client: client.current,
    onError: handleError,
    onCompleted: () => {
      onCompleted();
      setIsProcessCompleted(true);
    },
  });

  return (
    <Popup onClickClosePopup={() => onClose()} footer={<></>}>
      <>
        {addClassLoading && (
          <>
            <LoadingIcon isLoading={true} />
            <div className="h-flex-display l-flex--justify-center m-t-30">
              <p className="m-b-30">Please wait...</p>
            </div>
          </>
        )}
        {isProcessCompleted && (
          <>
            <p className="m-b-30">You successfully removed the students from their classes.</p>
            <div className="c-popup-footer-controls">
              <Button onClick={() => onClose()} filled color="primary">
                Confirm
              </Button>
            </div>
          </>
        )}

        {!isProcessCompleted && !addClassLoading && (
          <>
            <p className="m-b-30">You are about to create a new class .</p>
            <p>Enter a new name for the class.</p>
            <Form
              id="update-class"
              className="l-flex__item--column h-with-small-margin-top"
              onSubmit={(values, actions) => {
                const { name: newName } = values;
                setErrorMessages([]);
                executeAddClass({ variables: { groupId, name: newName } });
              }}
              initialValues={{
                name: '',
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().label('First name').required('is required'),
              })}
            >
              {({ handleSubmit }) => {
                return (
                  <>
                    <InputField hasBorder name="name" type="name" label="Class name" required />
                    <div className="c-popup-footer-controls">
                      <Button
                        onClick={() => {
                          onClose();
                        }}
                        color="secondary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          handleSubmit();
                        }}
                        filled
                        color="primary"
                      >
                        Confirm
                      </Button>
                    </div>
                  </>
                );
              }}
            </Form>
          </>
        )}
        <PopupErrorMessages messages={errorMessages} />
      </>
    </Popup>
  );
};
export default AddClassPopup;
