import { Button } from 'components/Shared/Inputs/Button';
import { Popup } from 'components/Shared/UI/Popup/Popup';
import { IRemoveUsersFromUnitPopup } from './RemoveUsersFromUnitPopup.interface';
import gql from 'graphql-tag';
import { useMutation } from 'utils/hooks/useMutation';
import { Client } from 'graphql/client';
import { useRef, useState } from 'react';
import { LoadingIcon } from 'components/Spinners/LoadingIcon';

const removeFromUnits = gql`
  mutation removeUsersFromUnit($membershipIds: [Int]!) {
    membership {
      removeFromUnit(membershipIds: $membershipIds)
    }
  }
`;

const RemoveUsersFromUnitPopup = ({ onClose, onCompleted, membershipIds }: IRemoveUsersFromUnitPopup) => {
  const client = useRef(Client('gelada'));

  const [isProcessCompleted, setIsProcessCompleted] = useState<boolean>(false);
  const [executeJoinUnit, { loading: removeFromUnitLoading }] = useMutation(removeFromUnits, {
    client: client.current,
    onCompleted: () => {
      onCompleted();
      setIsProcessCompleted(true);
    },
  });

  const onConfirmClicked = () => {
    executeJoinUnit({ variables: { membershipIds } });
  };

  return (
    <Popup onClickClosePopup={() => onClose()} footer={<></>}>
      <>
        {removeFromUnitLoading && (
          <>
            <LoadingIcon isLoading={true} />
            <div className="h-flex-display l-flex--justify-center m-t-30">
              <p className="m-b-30">Please wait...</p>
            </div>
          </>
        )}
        {isProcessCompleted && (
          <>
            <p className="m-b-30">You successfully removed the students from their classes.</p>
            <div className="c-popup-footer-controls">
              <Button onClick={() => onClose()} filled color="primary">
                Confirm
              </Button>
            </div>
          </>
        )}
        {!isProcessCompleted && !removeFromUnitLoading && (
          <>
            <p className="m-b-30">You are about to remove {membershipIds.length} students from their classes.</p>
            <div className="c-popup-footer-controls">
              <Button
                onClick={() => {
                  onClose();
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button onClick={onConfirmClicked} filled color="primary">
                Confirm
              </Button>
            </div>
          </>
        )}
      </>
    </Popup>
  );
};

export default RemoveUsersFromUnitPopup;
