import { IDeleteClassProps } from './DeleteClassPopup.interface';
import { Button } from 'components/Shared/Inputs/Button';
import { Popup } from 'components/Shared/UI/Popup/Popup';
import gql from 'graphql-tag';
import { useMutation } from 'utils/hooks/useMutation';
import { Client } from 'graphql/client';
import { useRef, useState } from 'react';
import { LoadingIcon } from 'components/Spinners/LoadingIcon';
import { getErrorMessages } from 'utils';

const DeleteClass = gql`
  mutation deleteClass($classId: Int!) {
    unit {
      remove(unitId: $classId)
    }
  }
`;

const DeleteClassPopup = ({ classToDelete, onClose, onCompleted }: IDeleteClassProps) => {
  const client = useRef(Client('gelada'));

  const [isProcessCompleted, setIsProcessCompleted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string[]>();

  function handleError(error) {
    const errorMessages = getErrorMessages(error);
    setErrorMessage(errorMessages);
  }

  const [executeDeleteClass, { loading: deleteClassLoading }] = useMutation(DeleteClass, {
    client: client.current,
    onError: handleError,
    onCompleted: () => {
      onCompleted();
      setIsProcessCompleted(true);
    },
  });

  const onConfirmClicked = () => {
    executeDeleteClass({ variables: { classId: classToDelete.id } });
  };

  return (
    <Popup onClickClosePopup={() => onClose()} footer={<></>}>
      <>
        {deleteClassLoading && (
          <>
            <LoadingIcon isLoading={true} />
            <div className="h-flex-display l-flex--justify-center m-t-30">
              <p className="m-b-30">Please wait...</p>
            </div>
          </>
        )}
        {isProcessCompleted && (
          <>
            <p className="m-b-30">You successfully removed the students from their classes.</p>
            <div className="c-popup-footer-controls">
              <Button onClick={() => onClose()} filled color="primary">
                Confirm
              </Button>
            </div>
          </>
        )}
        {!isProcessCompleted && !deleteClassLoading && (
          <>
            <p className="m-b-30">
              You are about to remove the class <strong>{classToDelete.name}</strong>.
            </p>
            <div className="c-popup-footer-controls">
              <Button
                onClick={() => {
                  onClose();
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button onClick={onConfirmClicked} filled color="primary">
                Confirm
              </Button>
            </div>
          </>
        )}
      </>
    </Popup>
  );
};
export default DeleteClassPopup;
