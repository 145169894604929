import { useState } from 'react';

import { gql } from '@apollo/client';
import { useGroup } from 'contexts/Authorization/Context';
import { client } from 'graphql/geladaClient';
import { useRouter } from 'next/router';
import { useMutation } from 'utils/hooks/useMutation';
import { useProtectedRoute } from 'utils/hooks/useProtectedRoute';
import * as Yup from 'yup';

import { ButtonLink, Button } from 'components/Shared/Inputs/Button';
import { Form } from 'components/Shared/Forms/Form';
import { Information } from 'components/Shared/UI/Information';
import { InputField } from 'components/Shared/UI/InputField';
import { Link } from 'components/Shared/UI/Link';
import { Select } from 'components/Shared/Inputs/Select';
import { Table } from 'components/Table';
import { XlxsFileInput } from 'components/Shared/Inputs/XlxsFileInput/XlxsFileInput';

const inviteStudentsMutation = gql`
  mutation inviteStudentsMutation($groupId: Int!, $users: [UserInput]!, $customMessage: String) {
    invitation {
      groupInvitationForMultipleUsers(
        invitation: {
          groupId: $groupId
          permission: READ
          users: $users
          userTypeId: 11
          customMessage: $customMessage
          createUnitIfNotInGroup: true
        }
      )
    }
  }
`;

const InviteStudents = ({ isSmallerDevice }) => {
  useProtectedRoute('Teacher');
  const group = useGroup();
  const { reload } = useRouter();
  const [students, setStudents] = useState<any[]>();
  const [invalidStudents, setInvalidStudents] = useState<any[]>();
  const [inviteOption, setInviteOption] = useState('bulkInvite');
  const [existingInvitationError, setExistingInvitationError] = useState<string>(undefined);
  const tableColumns = [
    { title: 'First name', key: 'Firstname', width: 10, visibleOnValidTable: true },
    { title: 'Last name', key: 'Lastname', width: 10, visibleOnValidTable: true },
    { title: 'Class', key: 'Class', width: 10, visibleOnValidTable: true },
    { title: 'Year group', key: 'YearGroup', width: 10, visibleOnValidTable: true },
    { title: 'School email', key: 'SchoolEmail', width: 30, visibleOnValidTable: true },
    { title: 'Reason', key: 'Reason', width: 10, visibleOnValidTable: false },
  ];

  function isNumber(value: string | number): boolean {
    return value != null && value !== '' && !isNaN(Number(value.toString()));
  }

  const invalidStudentTableData = (invalidStudents || []).map((student) => {
    const columns = tableColumns
      .filter((r) => r.key !== 'Reason')
      .map(({ key }) => {
        return <p>{student[key]}</p>;
      });

    const { Firstname, Lastname, Class, YearGroup, SchoolEmail } = student;

    let errorMessage = '';
    if (!Firstname || !Lastname || !SchoolEmail || !Class || !YearGroup) {
      errorMessage = 'Missing required field(s)';
    }

    if (!isNumber(YearGroup) || Number(YearGroup) > 12 || Number(YearGroup) < 1) {
      errorMessage += ' Invalid year group';
    }

    if (!isValidEmail(SchoolEmail)) {
      errorMessage += ' Invalid email';
    }

    if (errorMessage) {
      columns.push(<span>{errorMessage}</span>);
    }
    return {
      data: student,
      columns,
    };
  });

  const tableData = (students || []).map((student) => {
    return {
      data: student,
      columns: tableColumns
        .filter((r) => r.visibleOnValidTable)
        .map(({ key }) => {
          return <p>{student[key]}</p>;
        }),
    };
  });

  function handleAddUser({ firstname, lastname, year, email, className }) {
    setStudents([
      ...(students ?? []),
      {
        Firstname: firstname,
        Lastname: lastname,
        Class: className,
        YearGroup: year,
        SchoolEmail: email,
      },
    ]);
  }

  const handleClearList = () => {
    setStudents(null);
    setInvalidStudents(null);
  };

  const [sendInviteStudentsMutation, { data, loading }] = useMutation(inviteStudentsMutation, {
    client,
    onError(error) {
      // @ts-ignore
      setExistingInvitationError(error.networkError?.result?.errors?.[0]?.message);
    },
  });

  function handleSubmit({ note }) {
    setExistingInvitationError('');
    const users = (students || []).map((student) => {
      return {
        email: student.SchoolEmail,
        firstName: student.Firstname,
        lastName: student.Lastname,
        unitCode: String(student.Class),
        customFields: [
          {
            customFieldId: 3,
            value: String(student.YearGroup),
          },
        ],
      };
    });

    sendInviteStudentsMutation({
      variables: {
        groupId: group.id,
        customMessage: note,
        users,
      },
    });
  }

  function isValidEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function uploadStudents(localStudents) {
    setInvalidStudents(
      localStudents.filter(
        ({ SchoolEmail, YearGroup, Firstname, Lastname, Class }) =>
          SchoolEmail !== 'ourfutures.example@det.nsw.edu.au' &&
          (!isNumber(YearGroup) ||
            !isValidEmail(SchoolEmail) ||
            Number(YearGroup) > 12 ||
            Number(YearGroup) < 1 ||
            !Firstname ||
            !Lastname ||
            !Class),
      ),
    );

    setStudents(
      localStudents.filter(
        ({ SchoolEmail, YearGroup, Firstname, Lastname, Class }) =>
          SchoolEmail !== 'ourfutures.example@det.nsw.edu.au' &&
          isNumber(YearGroup) &&
          isValidEmail(SchoolEmail) &&
          Number(YearGroup) <= 12 &&
          Number(YearGroup) >= 1 &&
          Firstname &&
          Lastname &&
          Class,
      ),
    );
  }

  const isDisplayBulkInviteStep3and4 = (inviteOption === 'bulkInvite' && students) || inviteOption === 'individualInvite';

  return (
    <>
      <section
        className={`l-full-width l-position--relative h-with-2x-large-y-padding ${
          !isSmallerDevice ? 'h-with-4x-large-x-padding' : 'h-with-2x-large-x-padding'
        }`}
      >
        <div className="l-responsive-grid">
          <div className="l-full-width">
            <h1>
              Invite <span className="c-typography--emphisis">students</span>
            </h1>
            <div className={`l-flex l-flex-gap ${isSmallerDevice && 'l-flex-wrap'}`}>
              <p>
                On this page, you can invite students in bulk or individually from
                <span className="c-typography--emphisis"> {group?.name}</span>. Please select the appropriate action from the drop-down
                menu.
              </p>
              <div className="c-invite-students__invite-select">
                <Select
                  name="module_list"
                  onChange={(value) => setInviteOption(value)}
                  buttonClassName="l-full-width h-no-border"
                  selectTitle="Upload options"
                  options={[
                    {
                      value: 'individualInvite',
                      content: 'Invite individual students',
                    },
                    {
                      value: 'bulkInvite',
                      content: 'Invite in bulk',
                    },
                  ]}
                  defaultValue={0}
                  color="secondary"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {data ? (
        <section className="l-full-width h-background--color-grey-300 h-overflow--hidden">
          <div className="l-container l-container--medium">
            <h2 className="l-flex l-flex--align-center">Invitations sent!</h2>
            <p>
              <span className="c-typography--bold">What happens now?</span> The students you sent this to will receive an email with
              instructions on how to join OurFutures. You will be able to manage invited students on the{' '}
              <Link href="/teacher/manage-students">manage students</Link> page.
            </p>
            <div className="l-flex__item--column">
              <Table itemName="Student" columns={tableColumns.map(({ key, ...other }) => other)} data={tableData} />
            </div>
            <div className="l-flex__item--column l-flex l-flex--justify-end">
              <Button icon="arrow" rotateIcon="90" iconPosition="left" onClick={reload}>
                Manage students
              </Button>
            </div>
          </div>
        </section>
      ) : (
        <section className="l-full-width h-background--color-grey-300  h-overflow--hidden">
          <div className="l-container l-container--medium">
            <Form
              id="student_invite"
              initialValues={{
                note: '',
              }}
              validationSchema={Yup.object().shape({
                note: Yup.string(),
              })}
              onSubmit={handleSubmit}
            >
              {inviteOption === 'bulkInvite' ? (
                <div className="h-full-width l-flex l-flex--justify-space-between l-flex--align-center l-flex--wrap h-with-y-padding">
                  <ol className="h-font-weight--700 h-font-size--large">
                    <li>Step 1: Download and complete the excel template</li>
                    <li>Step 2: Upload the completed excel template</li>
                  </ol>
                  <ButtonLink href="/files/student_import.xlsx" icon="download" className="h-with-y-margin">
                    Download Excel template
                  </ButtonLink>
                </div>
              ) : null}
              {inviteOption === 'individualInvite' ? (
                <Form
                  id="add-user"
                  className="l-flex__item--column h-with-small-margin-top"
                  onSubmit={(values, actions) => {
                    const { year } = values;
                    handleAddUser(values);
                    actions.resetForm();
                    actions.setFieldValue('year', year);
                  }}
                  initialValues={{
                    className: '',
                    year: null,
                    firstname: '',
                    lastname: '',
                    email: '',
                    email_confirm: '',
                  }}
                  validationSchema={Yup.object().shape({
                    firstname: Yup.string().label('First name').required('is required'),
                    lastname: Yup.string().label('Last name').required('is required'),
                    email: Yup.string().label('Email').email().required('is required'),
                    className: Yup.string().label('Class').required('is required'),
                    year: Yup.number().label('year').required('is required').min(1).max(12),
                  })}
                >
                  {({ handleSubmit }) => {
                    return (
                      <>
                        <div className="l-flex l-flex--mobile-column l-full-width">
                          <InputField
                            className="l-flex--1"
                            name="firstname"
                            type="text"
                            label="First name"
                            autoComplete="given-name"
                            required
                            orientation="row"
                          />
                          <InputField
                            className="l-flex--1"
                            name="lastname"
                            type="text"
                            label="Last name"
                            autoComplete="family-name"
                            required
                            orientation="row"
                          />
                        </div>
                        <InputField name="email" type="email" label="Email address" autoComplete="email" required />
                        <InputField name="className" type="text" label="Class" autoComplete="email" required />
                        <InputField name="year" type="number" label="Year" autoComplete="email" required />
                        <div className="h-with-y-margin l-full-width l-flex l-flex--justify-space-between">
                          <div>
                            <p className="h-font-weight--700">List of students to invite</p>
                            <ul className={!isSmallerDevice && 'l-flex'}>
                              {students &&
                                students.map(({ Firstname, Lastname, YearGroup }, index) => {
                                  const fullName =
                                    index + 1 === students.length
                                      ? `${Firstname} ${Lastname}`
                                      : `${Firstname} ${Lastname}${!isSmallerDevice ? ',\u00A0' : ''}`;
                                  return <li key={`${Firstname}-${Lastname}-${YearGroup}`}>{fullName}</li>;
                                })}
                            </ul>
                          </div>
                          <div>
                            <Button
                              className="l-flex__item--row"
                              onClick={() => {
                                handleSubmit();
                              }}
                              rotateIcon="270"
                            >
                              Add
                            </Button>
                          </div>
                        </div>
                      </>
                    );
                  }}
                </Form>
              ) : (
                <div className="l-flex__item--column">
                  {(students || invalidStudents) && (
                    <div>
                      <Button className="float-right h-with-margin" onClick={handleClearList}>
                        Clear list
                      </Button>
                    </div>
                  )}
                  {Boolean(students) && (
                    <ol className="h-font-weight--700 h-font-size--large">
                      <li className="c-invite-students-table-information h-with-y-padding">
                        Step 3: Review the list of {students.length === 1 ? 'a student' : `${students.length} students`} along with their
                        details.
                      </li>
                    </ol>
                  )}
                  {invalidStudents && invalidStudents.length > 0 && (
                    <Table
                      itemName="Invalid student"
                      columns={tableColumns.map(({ key, ...other }) => other)}
                      data={invalidStudentTableData}
                    />
                  )}
                  {students && (
                    <Table
                      className="c-invite-students-table"
                      itemName="Student"
                      columns={tableColumns.filter((r) => r.visibleOnValidTable).map(({ key, ...other }) => other)}
                      data={tableData}
                    />
                  )}
                  {!students && <XlxsFileInput className="h-with-padding" onConverted={uploadStudents} />}
                </div>
              )}
              {isDisplayBulkInviteStep3and4 ? (
                <>
                  <div className="l-flex__item--column h-with-y-padding">
                    <ol className="h-with-y-padding h-font-weight--700 h-font-size--large">
                      <li>{`${inviteOption === 'bulkInvite' ? 'Step 4: ' : ''}`}Add a personal note (optional)</li>
                    </ol>
                    <InputField component="textarea" className="l-full-width" name="note" label="Add a personal note (optional)" />
                  </div>
                  <p className="h-with-y-padding">
                    <span className="c-typography--bold">What happens next?</span> Students will receive an email with instructions on how
                    to join OurFutures. You will be able to review the list of invited students on the invite students page, and once
                    registered, they will be listed on the
                    <Link className="c-invite-students__info-email" href="/teacher/manage-students" isExternal>
                      &nbsp;manage students page &nbsp;
                    </Link>
                  </p>
                </>
              ) : null}
              <div className="l-flex l-flex--justify-end h-with-y-margin">
                {existingInvitationError && <Information typeLetter="!" message={existingInvitationError} />}
                <Button type="submit" isDisabled={!students || loading || (students && !students.length)}>
                  Send
                </Button>
              </div>
            </Form>
          </div>
        </section>
      )}
    </>
  );
};

export { InviteStudents };
