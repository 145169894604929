import React from 'react';

import cx from 'classnames';

import { Button } from 'components/Shared/Inputs/Button';
import { Information } from 'components/Shared/UI/Information';
import { Table } from 'components/Table';

const PendingStudents = ({
  isFetchMoreInvitationsLoading,
  isSmallerDevice,
  rows,
  totalCountInvitations,
  onChangePageSize,
  onChangeSearchValue,
  onClickFetchMore,
  onClickGetInvitations,
  onClickTab,
}) => {
  return (
    <>
      <div className={`${!isSmallerDevice ? 'h-with-4x-large-x-padding' : 'h-with-2x-large-x-padding'} h-with-2x-large-y-padding `}>
        <div className="l-flex l-flex--justify-space-between h-with-y-large-padding-bottom">
          <h1>
            Pending <span className="c-typography--emphisis">students</span>
          </h1>
        </div>
        <span>
          On this page, you will see the names of all students from your school who have been invited to create an OurFutures account. This
          page allows you to:
        </span>
        <ul className="c-manage-students__information-list">
          <li className="c-manage-students__information-list-item">
            View invitations that have been sent, along with their current status
          </li>
          <li className="c-manage-students__information-list-item">Resend an invitation email to a student</li>
        </ul>
        <div className="h-with-y-xsmall-padding">
          <span>
            Please note that after inviting your students you will need to wait for them to activate their accounts before they will appear
            on the
          </span>
          &nbsp;
          <button className="c-typography--emphisis" type="button" onClick={onClickTab}>
            manage students
          </button>
        </div>
        <p>
          If you are experiencing issues with students receiving the activation email, please ensure that the
          <a
            className="c-invite-students__info-email h-text-decoration-none"
            href="mailto:info@ourfutures.education"
            target="_blank"
            rel="noopener noreferrer"
          >
            &nbsp;info@ourfutures.education&nbsp;
          </a>
          is whitelisted by your schools IT department. If the issue persists, please contact us using the above email or phone
          <a className="c-invite-students__info-number h-text-decoration-none" href="tel:info@ourfutures.education">
            &nbsp;(02) 7908 4027&nbsp;
          </a>
          and we will be able to assist.
        </p>
      </div>
      <section
        className={cx('l-full-width l-position--relative h-background--color-white pt-sm', {
          'l-container l-container--medium': !isSmallerDevice,
        })}
      >
        <div
          className={`l-flex l-flex--mobile-column l-full-width h-with-x-large--y-padding ${
            isSmallerDevice && 'h-with-2x-large-x-padding'
          }`}
        >
          <div className="l-flex__item--row" style={{ width: '15rem' }}>
            <label className="h-hide-visually" htmlFor="searchTermInvitations">
              Search invitations
            </label>
            <input
              className="c-input c-input--alt-background"
              id="searchTermInvitations"
              name="searchTermInvitations"
              placeholder="Filter by name or email"
              onChange={onChangeSearchValue}
            />
          </div>
          <Button
            className="c-input l-flex__item--row l-width--auto h-rounded-border l-flex__item--row"
            color="secondary"
            type="button"
            onClick={onClickGetInvitations}
            filled
          >
            Search
          </Button>
        </div>
        <Information message="Filter and select invited students" />
        <div className="l-flex__item--column">
          <Table
            onPageSizeChange={onChangePageSize}
            columns={[
              {
                isSortable: true,
                title: 'Name',
                width: 15,
              },
              {
                isSortable: true,
                priority: 1,
                title: 'Email',
                width: 25,
              },
              {
                priority: 1,
                title: 'Status',
                width: 5,
              },
              {
                isSortable: true,
                priority: 1,
                title: 'Date sent',
                width: 10,
              },
              {
                priority: 1,
                title: '',
                width: 10,
              },
            ]}
            data={rows}
            itemName="Invitations"
            isInformationVisible
            informationMessage="List of invited students"
          />
        </div>

        <div className="h-flex-display l-flex--justify-space-between">
          <Button
            className={isSmallerDevice ? 'h-with-margin' : 'h-with-y-margin'}
            isDisabled={!totalCountInvitations || totalCountInvitations === rows.length}
            isLoading={isFetchMoreInvitationsLoading}
            rotateIcon="270"
            onClick={onClickFetchMore}
          >
            View more
          </Button>
          {rows && (
            <p className="float-right bold h-color--brand l-vertical-centered">
              displaying {rows.length} of {totalCountInvitations}
            </p>
          )}
        </div>
      </section>
    </>
  );
};

export { PendingStudents };
