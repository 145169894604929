import { ButtonLink } from 'components/Shared/Inputs/Button';
import { Table } from 'components/Table';
import { useGroup } from 'contexts/Authorization/Context';
import gql from 'graphql-tag';
import { Client } from 'graphql/client';
import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';
import { LoadingOverlay } from 'components/Spinners/LoadingOverlay';
import { PERMISSIONS_MAP } from 'utils';

const GET_OUTSTANDING_USERS = gql`
  query getOutstandings($groupId: Int!, $customFields: [Int]) {
    membership {
      getOutstandingUsers(groupId: $groupId, customFields: $customFields) {
        unit {
          id
          name
        }
        user {
          credential {
            email
          }
          customFields {
            customFieldId
            id
            ... on CustomFieldResponseNumberGraphType {
              number
            }
          }
          id
          firstname
          lastname
        }
      }
    }
  }
`;

const OutstandingStudents = () => {
  const clientGelada = useRef(Client('gelada'));
  const currentGroup = useGroup();

  const [isReadMore, setIsReadMore] = useState<boolean>(false);
  const [memberships, setMemberships] = useState([]);
  const isSmallerDevice = useMediaQuery({ query: '(max-width: 1024px)' });

  const [executeFetchMoreUsers, { loading }] = useLazyQuery(GET_OUTSTANDING_USERS, {
    fetchPolicy: 'no-cache',
    client: clientGelada.current,
    anon: false,

    onCompleted(response) {
      const {
        membership: { getOutstandingUsers },
      } = response;

      setMemberships(getOutstandingUsers.map((r) => r).filter((r) => r.permision === PERMISSIONS_MAP.STUDENTS));
    },
  });

  useEffect(() => {
    executeFetchMoreUsers({
      variables: {
        groupId: currentGroup.id,
        customFields: [9],
      },
    });
  }, []);

  const invitationTableRows = memberships.map((membership) => {
    const {
      id: membershipId,
      unitId,
      unit,
      user: {
        firstname,
        lastname,
        credential: { email },
        customFields,
      },
    } = membership;

    const hasUnitBeenAssigned = Boolean(unitId);
    const year = customFields.find((c) => c.customFieldId === 3);
    const hasYearBeenAssigned = Boolean(year?.number);

    const fullName = `${firstname || '--'} ${lastname || ''}`;

    const data = {
      ...membership,
      isSelectable: true,
    };

    return {
      columns: [
        <div className="l-flex l-flex--align-center">
          <p className="l-flex__item--row h-no-margin">{fullName}</p>
          <div className="admin-alert">
            <ButtonLink
              className="c-table__small_button l-flex l-flex__item--row"
              color="secondary"
              href={`/teacher/manage-students/student?id=${membership.id}`}
              icon="new_window"
              title="Open student profile"
              isExternal
              round
            />
            <span className="admin-alert-message-profile">
              View/edit student profile
              <span className="admin-alert-message-arrow" />
            </span>
          </div>
        </div>,
        <div>{email || '--'}</div>,
        <div>
          {hasUnitBeenAssigned ? (
            unit?.name
          ) : (
            <div className="admin-alert">
              &#9888;
              <div className="admin-alert-message">
                Class has not been updated this year. Update by assigning student to a class.
                <div className="admin-alert-message-arrow" />
              </div>
            </div>
          )}
        </div>,
        <div>
          {hasYearBeenAssigned ? (
            year.number
          ) : (
            <div className="admin-alert">
              &#9888;
              <div className="admin-alert-message">
                Year has not been updated. Update by assigning a year to the student.
                <div className="admin-alert-message-arrow" />
              </div>
            </div>
          )}
        </div>,
      ],
      data,
      sortableValues: {
        0: fullName,
        1: email,
      },
    };
  });

  return (
    <>
      {loading && <LoadingOverlay />}
      <div
        className={`l-responsive-grid l-flex l-flex--justify-space-between l-flex--align-center l-flex-gap h-with-2x-large-y-padding ${
          !isSmallerDevice && 'h-with-x-padding'
        }`}
      >
        <div
          className={cx('l-flex l-flex--column h-with-2x-large-x-padding h-overflow--hidden', {
            'c-manage-students__read-more-content': isReadMore,
            'c-manage-students__read-less-content': !isReadMore,
          })}
        >
          <h1>
            Outstanding <span className="c-typography--emphisis">students</span>
          </h1>
          <div className={`l-flex l-flex-gap ${isSmallerDevice && 'l-flex-wrap'}`}>
            <p>
              On this page, you can see all the students that are misconfigured in
              <span className="c-typography--emphisis"> {currentGroup?.name}</span>.
            </p>
          </div>
        </div>
      </div>
      <div className="l-flex__item--column">
        <Table
          actions={[
            {
              buildHref: ({ selectedIds }) => ({
                pathname: '/teacher/manage-students/assign-class',
                query: { id: selectedIds },
              }),
              isDisabled({ selectedIds }) {
                return selectedIds.length === 0;
              },
              title: 'Assign to class',
              buttonTooltipChildren: ({ selectedIds }) => {
                return selectedIds.length ? (
                  <span>Click to assign to a class or move between class groups</span>
                ) : (
                  <span>Select the student(s) you wish to assign class</span>
                );
              },
              isDisplayButtonTooltip: true,
            },
          ]}
          columns={[
            {
              isSortable: true,
              title: 'Name',
              width: 20,
            },
            {
              isSortable: true,
              title: 'Email',
              width: 20,
            },
            {
              isSortable: true,
              title: 'Class',
              width: 7,
            },
            {
              isSortable: true,
              title: 'Year',
              width: 7,
            },
          ]}
          data={invitationTableRows}
          itemName="Student"
          selectable
          isInformationVisible
          informationMessage="Update the student that haven't been entirely configured."
        />
      </div>
    </>
  );
};

export { OutstandingStudents };
