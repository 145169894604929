import { Button } from 'components/Shared/Inputs/Button';
import { LoadingIcon } from 'components/Spinners/LoadingIcon';
import { Popup } from 'components/Shared/UI/Popup';
import Select from 'components/Shared/Inputs/Select/Select';
import { useGroup, useSetUnit } from 'contexts/Authorization/Context';
import gql from 'graphql-tag';
import { Client } from 'graphql/client';
import { GET_UNITS } from 'graphql/queries';
import { useEffect, useRef, useState } from 'react';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';
import { useMutation } from 'utils/hooks/useMutation';
import { SelectClassProps } from './SelectClass.interfaces';

const JOIN_UNIT = gql`
  mutation joinUnit($unitId: Int!) {
    unit {
      joinUnit(unitId: $unitId)
    }
  }
`;

export function SelectClass({ onClassSelected, onClose }: SelectClassProps) {
  const [unitItems, setUnitItems] = useState([]);
  const [units, setUnits] = useState([]);
  const [selectedUnitId, setSelectedUnitId] = useState<number>();

  const client = useRef(Client('gelada'));
  const currentGroup = useGroup();
  const handleSetUnit = useSetUnit();

  const [executeJoinUnit, { loading: isJoinUnitLoading }] = useMutation(JOIN_UNIT, {
    client: client.current,
    onCompleted() {
      const unit = units.find((r) => r.id === selectedUnitId);
      handleSetUnit(unit);
      onClassSelected();
    },
  });

  const [executeGetUnits, { loading: isGetUnitsLoading }] = useLazyQuery(GET_UNITS, {
    client: client.current,
    onCompleted(data) {
      const {
        unit: { getByGroup },
      } = data;

      setUnits(getByGroup);
      const unitItems = [];
      getByGroup.map((unit) => {
        unitItems.push({ value: unit.id, content: unit.name });
      });
      setUnitItems(unitItems);
    },
  });

  const onNextClicked = () => {
    executeJoinUnit({ variables: { unitId: selectedUnitId } });
  };

  useEffect(() => {
    executeGetUnits({ variables: { groupId: currentGroup.id } });
  }, []);

  const isLoading = isGetUnitsLoading || isJoinUnitLoading;
  return (
    <Popup onClickClosePopup={onClose} additionalClassNames="c-popup-select-class" header={isLoading ? <></> : <h2>Choose Your Class</h2>}>
      {isLoading && <LoadingIcon isLoading={isLoading} />}
      {!isLoading && (
        <>
          <p className="m-b-30">
            It appears that you are not currently assigned to any class. Please select a class from the options below:
          </p>
          <Select
            name="unit_list"
            onChange={(event) => {
              setSelectedUnitId(Number(event));
            }}
            buttonClassName=" h-no-border m-w-50"
            containerClassName="m-w-50"
            displayTitle
            selectTitle="Select a class"
            options={unitItems}
            defaultValue={0}
            value={0}
            color="secondary"
          />
          <p className="m-t-30">
            If you don't see your desired class listed or have any questions, feel free to contact your <strong>teacher</strong> or the{' '}
            <strong>administrator</strong> for assistance.
          </p>
          <p className="m-t-30">Thank you for your cooperation.</p>
          <div className="c-popup-select-class-controls">
            <Button disabled={!Boolean(selectedUnitId)} onClick={onNextClicked}>
              Confirm
            </Button>
          </div>
        </>
      )}
    </Popup>
  );
}
