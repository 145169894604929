import React, { useRef, useState } from 'react';

import { FileDrop } from 'react-file-drop';

import { Button } from 'components/Shared/Inputs/Button';

import { read, writeFileXLSX, utils } from 'xlsx';

export interface XlxsFileInputProps {
  className?: string;
  onConverted: (converted: Record<string, any>) => void;
}

const acceptedFileTypes = ['xls', 'xlsx'];

export function XlxsFileInput({ className, onConverted }: XlxsFileInputProps) {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState();

  const process = async (input) => {
    setFile(input);

    const reader = new FileReader();

    reader.onload = (event) => {
      const workbook = read(event.target.result, {
        type: 'binary',
      });

      const sheet = Object.values(workbook.Sheets)[0];

      const asObject = utils.sheet_to_json(sheet);

      onConverted(asObject);
    };

    reader.readAsBinaryString(input);
  };

  function onFileDroped(files) {
    process(files[0]);
  }

  function handleFileUpload(event) {
    process(event.target.files[0]);
  }

  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <div aria-hidden>
        <FileDrop
          className={`c-xlxs-input__file-drop l-full-width ${className}`}
          targetClassName="c-xlxs-input__file-drop-target l-flex l-flex--center h-background--color-grey-300 l-full-width l-flex--column h-with-padding h-border h-border--dashed h-border--color-grey-700"
          onDrop={onFileDroped}
          onTargetClick={onTargetClick}
        >
          <Button className="l-flex__item--column" icon="upload">
            Upload students
          </Button>
          <p className="l-flex__item--column">
            {file
              ? // @ts-ignore check for file existance is above
                `${file.name} uploaded.`
              : `Click or drag a file to upload.`}
          </p>
        </FileDrop>
      </div>
      <label className="h-sr-only">
        Upload {acceptedFileTypes.join(' or ')} file to invite students
        <input onChange={handleFileUpload} ref={fileInputRef} type="file" accept={acceptedFileTypes.map((ext) => `.${ext}`).join(',')} />
      </label>
    </>
  );
}
