import React, { ReactNode } from 'react';

import cx from 'classnames';
import pluralize from 'pluralize';
import { ButtonLink } from 'components/Shared/Inputs/Button';
import { Icon } from 'components/Shared/UI/Icon';
import { Link } from 'components/Shared/UI/Link';
import { EkardoPage } from 'components/Shared/Ekardo/EkardoPage';
import { ProgressCircle } from 'components/Shared/UI/ProgressCircle';
import { useMediaQuery } from 'react-responsive';
import { moduleUrlActivityPdfMap } from 'consts/activityPdfMap';
import { activityStudentPdfMap } from 'consts/activityStudentPdfMap';

export interface LessonCardProps {
  lesson: any;
  description: string | undefined;
  className: string;
  baseHref: string;
  lessonNumber: number;
  isLocked: boolean;
  moduleUrl: string;
}

export function LessonCard({ description, className, lesson, baseHref, lessonNumber, isLocked, moduleUrl }: LessonCardProps) {
  const {
    activityCompletionPercentage,
    lessonCompletionPercentage,
    cartoon,
    lastViewedPage,
    cartoonCompletionPercentage,
    image,
    activities,
    isCartoonCompleted,
    descriptionGroup,
  } = lesson;

  const {
    cartoon: { stepName: lessonName },
  } = lesson;

  const isLesson = lessonName.split(' ')[0].toLowerCase().includes('lesson');
  const lessonNameNumber = lessonName.split(' ')?.[1];

  const isCartoonStarted = cartoonCompletionPercentage !== 0;
  const isActivityCompleted = activityCompletionPercentage === 1;
  const hasActivity = activities.length > 0;

  let cartoonRoute = 'begin';
  let actionText = 'Start';

  if (isCartoonStarted) actionText = 'Continue';
  if (isCartoonCompleted) actionText = 'Review';
  if (isLocked) actionText = 'Locked';

  const describePage = descriptionGroup.find((page) => {
    return page.contentPageType?.name === 'Description';
  });

  const studentSummaryPage = descriptionGroup.find((page) => {
    return page.contentPageType?.name === 'Student summary';
  });

  if (lastViewedPage && !isCartoonCompleted) {
    cartoonRoute = lastViewedPage.url;
  }

  const cartoonHref = `${baseHref}/${cartoon.url}/${cartoonRoute}`;
  const activityHref = `${baseHref}/activities/lesson-${lessonNumber}`;
  const summaryHref = studentSummaryPage ? `${baseHref}/summary/${studentSummaryPage.url}` : '';
  const percentage = Math.floor(lessonCompletionPercentage * 100);
  const isLargerDevice = useMediaQuery({ query: '(min-width: 768px)' });

  const moduleActivityEkardoUrl = activityStudentPdfMap[moduleUrl.split('-').join('')];

  const lessonImage = image ? image.presignedUrl : '/images/group-shots-casual.png';

  return (
    <li className={cx(`c-student-lesson-card c-lesson-card l-flex l-full-width h-background--color-white ${className}`)}>
      <div className="l-flex l-flex--column l-flex--justify-space-between">
        <img
          height="250"
          width="220"
          className="c-lesson-card__scene c-lesson-card__image h-background--color-brand-secondary"
          src={lessonImage}
          alt={image ? image?.alt : ''}
        />
        <ButtonLink
          isDisabled={isLocked}
          className="c-lesson-card__cartoon-button c-lesson-card__cartoon---active"
          href={cartoonHref}
          icon="arrow"
          rotateIcon="270"
        >
          {actionText}
        </ButtonLink>
      </div>
      <div className="c-lesson-card__content h-with-y-margin l-full-width l-flex l-flex--column l-position--relative">
        <h2>
          <span className="l-flex l-flex--align-center">
            {isLesson && (
              <>
                <span className="l-flex__item--row">Lesson </span>
                <span className="l-flex__item--row h-circled h-circled--small h-background--color-brand-secondary l-flex l-flex--center c-typography--bold">
                  {lessonNameNumber}
                </span>
              </>
            )}
            {!isLesson && <span className="l-flex__item--row">{lessonName}</span>}
          </span>
        </h2>

        <ButtonLink className="l-position-h-right h-4xl-margin-top" isDisabled={isLocked} href={cartoonHref} rotateIcon="270" icon="arrow">
          {actionText} {lessonName}
        </ButtonLink>
        <div className={cx('l-flex', 'l-flex--column', 'l-flex--justify-space-between', 'l-full-height')}>
          <EkardoPage smallLoader isInsideContainer pageUrl={describePage?.url} />

          <div className="ek-text-fsnippet" dangerouslySetInnerHTML={{ __html: description }} />
          <div className="l-flex l-flex-wrap l-flex-gap">
            {summaryHref && (
              <Link className="l-flex__item--row" isExternal href={summaryHref}>
                <span className="l-flex l-flex--align-center">
                  <Icon id="notepad" className="l-flex__item--row" color="secondary" />
                  <span className={cx('l-flex__item--row', 'c-typography--semi-bold', 'c-typography--color-secondary')}>Summary</span>
                </span>
              </Link>
            )}

            {moduleActivityEkardoUrl && lessonNameNumber && (
              <Link
                className="l-flex__item--row"
                href={`${moduleActivityEkardoUrl}lesson${lessonNameNumber}-student.pdf`}
                isExternal
                isDisabled={!moduleActivityEkardoUrl}
              >
                <span className={`l-flex l-flex--align-center ${!moduleActivityEkardoUrl && 'opacity-0'}`}>
                  <Icon id="notepad" className="l-flex__item--row" color="secondary" />
                  <span className={cx('l-flex__item--row', 'c-typography--semi-bold', 'c-typography--color-secondary')}>
                    Additional Activities
                  </span>
                </span>
              </Link>
            )}
            {hasActivity && (
              <>
                <Link
                  style={{
                    marginLeft: isLargerDevice ? '1rem' : '0',
                    textDecoration: 'none',
                  }}
                  className="l-flex__item--row"
                  href={activityHref}
                >
                  <span className="l-flex l-flex--align-center">
                    <Icon id="notepad" className="l-flex__item--row" color={isActivityCompleted ? 'green' : 'secondary'} />
                    <span
                      className={cx('l-flex__item--row', 'c-typography--semi-bold', {
                        'c-typography--color-brand': isActivityCompleted,
                        'c-typography--color-tertiary': !isActivityCompleted,
                      })}
                    >
                      {pluralize('Activity', activities.length)}
                    </span>
                  </span>
                </Link>
                <span className="l-flex l-flex__item--row l-flex--align-center" style={{ marginLeft: isLargerDevice ? '1rem' : '0' }}>
                  <Icon
                    id="tick"
                    className="l-flex__item--row"
                    borderColor={isActivityCompleted ? 'green' : 'grey-700'}
                    backgroundColor={isActivityCompleted ? 'green' : 'white'}
                    color={isActivityCompleted ? 'brand' : 'white'}
                  />
                  <p
                    className={`${
                      isActivityCompleted ? 'c-typography--color-brand' : 'c-typography--color-grey-700'
                    } l-flex__item--row c-typography--semi-bold`}
                  >
                    {isActivityCompleted ? 'Complete' : 'Incomplete'}
                  </p>
                </span>
              </>
            )}
          </div>
        </div>

        <ProgressCircle className="c-research-feedback-list__icon p-student-index__module-progress" percent={percentage} showPercent />
      </div>
    </li>
  );
}
