import { gql } from '@apollo/client';

export const GET_GROUP_USERS = gql`
  query getGroupUsers($groupId: Int!, $permission: EMemberPermission) {
    group {
      getUsers(groupId: $groupId, permission: $permission) {
        id
        userId
        user {
          firstname
          lastname
          customFields {
            customFieldId
            id
            ... on CustomFieldResponseNumberGraphType {
              number
            }
          }
        }
        unit {
          id
        }
      }
    }
  }
`;

export const GetUnits = gql`
  query getUnits($groupId: Int!) {
    group {
      getGroup(groupId: $groupId) {
        unit {
          id
          name
        }
      }
    }
  }
`;

export const GetUnitsContentModes = gql`
  query getUnitsContentModes($unitIds: [Int]!) {
    unit {
      get(unitIds: $unitIds) {
        id: unitId
        contentGroup {
          id
          groupingContentGroup {
            id
          }
        }
        mode
      }
    }
    userFlow {
      searchUserFlows(projectId:  "${process.env.REACT_APP_PROJECT_ID}",isVisible:true) {
        id
        title
        url
        userFlowSteps {
          id
          userFlowId
          url
          sort
          contentGroup {
            id
            title
            type
            groupingContentGroup{
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_UNITS = gql`
  query getUnits($groupId: Int!) {
    unit {
      getByGroup(groupId: $groupId) {
        id
        name
      }
    }
  }
`;

export const GET_GROUP_INVITATIONS = gql`
  query getGroupInvitations($after: String, $first: Int, $filter: String, $groupId: Int) {
    invitation {
      getGroupInvitations(after: $after, first: $first, projectId: "${process.env.REACT_APP_PROJECT_ID}", filter: $filter, groupId: $groupId) {
        edges {
          cursor
          node {
            created
            id
            invitedUserEmail: emailUserInvited
            customMessage
            firstName
            lastName
            groupId
            invitedUserId
            invitationEmailStatus
            group {
              id
              name
            }
            userType {
              id
              name
            }
          }
        }
        totalCount
      }
    }
  }
`;
