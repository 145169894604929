import React, { ReactNode } from 'react';

import { useWindowSize } from 'utils/hooks/useWindowSize';

import { ButtonLink } from 'components/Shared/Inputs/Button';

export interface ModuleCardProps {
  as?: string;
  button?: ReactNode;
  className?: string;
  description: ReactNode;
  href?: string;
  imageUrl: string;
  isAccessible?: boolean;
  orphaned?: boolean;
  price?: number;
  supTitle?: string;
  title: string;
}

export function ModuleCard({
  className,
  description,
  imageUrl,
  orphaned,
  supTitle,
  title,
  href,
  as,
  price,
  isAccessible = true,
}: ModuleCardProps) {
  const Component = orphaned ? 'div' : 'li';

  const { isMobile, isTablet, isDesktop } = useWindowSize();

  return (
    <Component className={`c-lesson-card l-flex l-full-width ${className}`}>
      {isDesktop ? (
        <div
          className={`c-lesson-card__image h-background--color-brand-secondary ${isAccessible ? '' : 'locked'}`}
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
      ) : null}
      <div className="c-lesson-card__body l-full-width l-flex l-flex--column l-position--relative">
        {supTitle && <p className="c-typography--intro">{supTitle}</p>}
        <h1 className="c-typography--special">{title}</h1>
        <div className="l-flex l-flex--mobile-column">
          {isMobile || isTablet ? (
            <div className="c-lesson-card__image h-background--color-brand-secondary" style={{ backgroundImage: `url(${imageUrl})` }} />
          ) : null}
          <div className="c-lesson-card__description">{description}</div>
        </div>

        {href && (
          <ButtonLink
            className="c-module-card__cta"
            href={isAccessible ? href : 'https://ourfuturesinstitute.org.au/contact/'}
            as={as}
            icon="arrow"
            rotateIcon="270"
          >
            {isAccessible ? 'Enter' : 'Get in touch'}
          </ButtonLink>
        )}
      </div>
    </Component>
  );
}
