import React, { useState } from 'react';

import cx from 'classnames';

import { Button, ButtonLink } from 'components/Shared/Inputs/Button';
import { Form } from 'components/Shared/Forms/Form';
import { InputField } from 'components/Shared/UI/InputField';
import { Select } from 'components/Shared/Inputs/Select';
import { Table } from 'components/Table';
import RemoveUsersFromUnitPopup from 'components/Popups/RemoveUsersFromUnitPopup/RemoveUsersFromUnitPopup';
import { LoadingIcon } from 'components/Spinners/LoadingIcon';

const ManageStudents = ({
  isSmallerDevice,
  onClickInvite,
  onClickPending,
  classFilter,
  rows,
  yearFilter,
  onClickYearFilter,
  isTableLoading,
  onClickClassFilter,
  onChangePageSize,
  onSubmitForm,
  totalCount,
  isGetGroupUsersLoading,
  isFetchMoreUsers,
  refreshStudents,
  onClickFetchMore,
  onSelectedUnitChanged,
  onSelectedAssignedFilterChange,
  selectedUnit,
  units,
}) => {
  const [isReadMore, setIsReadMore] = useState<boolean>(false);
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [isUnassignedPopupVisible, setIsUnassignedPopupVisible] = useState<boolean>(false);
  const [selectedAssignedFilter, setSelectedAssignedFilter] = useState<string>('assigned');
  const readLessOrMore = isReadMore ? 'less' : 'more';

  const unitItems = [];
  unitItems.push({ content: 'All', value: null });
  units.map((unit) => {
    unitItems.push({ value: unit.id, content: unit.name });
  });

  return (
    <>
      <div
        className={`l-responsive-grid l-flex l-flex--justify-space-between l-flex--align-center l-flex-gap h-with-2x-large-y-padding ${
          !isSmallerDevice && 'h-with-x-padding'
        }`}
      >
        <div
          className={cx('l-flex l-flex--column h-with-2x-large-x-padding h-overflow--hidden', {
            'c-manage-students__read-more-content': isReadMore,
            'c-manage-students__read-less-content': !isReadMore,
          })}
        >
          <h1>
            Manage <span className="c-typography--emphisis">students</span>
          </h1>
          <span>On this page, you can:</span>
          <ul className="c-manage-students__information-list">
            <li className="c-manage-students__information-list-item">Assign students to, or move them between, class groups</li>
            <li className="c-manage-students__information-list-item">
              Edit individual student details including their name, email address, and year group
            </li>
            <li className="c-manage-students__information-list-item">Send a password reset email to individual or a group of students</li>
            <li className="c-manage-students__information-list-item">Assign a student leader</li>
          </ul>
          {readLessOrMore === 'less' && (
            <>
              <span>
                Please use the&nbsp;
                <button className="c-typography--emphisis" type="button" onClick={onClickInvite}>
                  invite students
                </button>
                &nbsp;tab to:
              </span>
              <ul className="c-manage-students__information-list">
                <li className="c-manage-students__information-list-item">
                  Invite students to the site, either in bulk or individually via the invite students button at the top of the page
                </li>
              </ul>
              <span>
                Please use the&nbsp;
                <button className="c-typography--emphisis" type="button" onClick={onClickPending}>
                  pending students
                </button>
                &nbsp;tab to:
              </span>
              <ul className="c-manage-students__information-list">
                <li className="c-manage-students__information-list-item">View invitations that have been sent, along with their status</li>
                <li className="c-manage-students__information-list-item">Resend an invitation email to a student(s)</li>
              </ul>
              <span>View step-by-step guides for:</span>
              <ul className="c-manage-students__information-list">
                <li className="c-manage-students__information-list-item">
                  How to Invite Students
                  <ButtonLink
                    className="c-table__small_button l-flex l-flex__item--row"
                    color="secondary"
                    href="https://app.ourfutures.education/how-to-invite-students-to-your-schoolorganisations-ourfutures-account"
                    icon="new_window"
                    title="Open student profile"
                    isExternal
                    round
                  />
                </li>
              </ul>
            </>
          )}
          <div className="c-manage-students__read-more-button l-flex l-flex--align-start l-flex--justify-start">
            <div className="l-flex l-full-width">
              <Button onClick={() => setIsReadMore((val) => !val)} icon="arrow" rotateIcon={isReadMore ? '180' : '0'} isNaked>
                Read {readLessOrMore}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <section
        className={cx('l-full-width l-position--relative h-background--color-white pt-sm', {
          'l-container l-container--medium': !isSmallerDevice,
        })}
      >
        <Form
          className="l-flex__item--column h-with-y-padding"
          id="forum_filter"
          initialValues={{ searchTerm: '' }}
          onSubmit={onSubmitForm}
        >
          <div className={`l-flex l-flex--mobile-column l-full-width ${isSmallerDevice && 'h-with-2x-large-x-padding'}`}>
            <div className="l-flex__item--row" style={{ width: '15rem' }}>
              <InputField name="searchTerm" label="Filter by name" altBackground hideError rounded />
            </div>
            <Button
              className="c-input l-flex__item--row l-width--auto h-rounded-border l-flex__item--row"
              color="secondary"
              type="submit"
              filled
            >
              Search
            </Button>

            {Boolean(yearFilter) && (
              <button type="button" className="ml-lg h-background--color-brand l-flex__item--row display-table" onClick={onClickYearFilter}>
                <span className="table-cell-centered h-font-weight--600 h-with-padding pointer">Year {yearFilter} X</span>
              </button>
            )}

            {Boolean(classFilter) && (
              <button
                type="button"
                className="ml-lg h-background--color-brand l-flex__item--row display-table"
                onClick={onClickClassFilter}
              >
                <span className="table-cell-centered h-font-weight--600 h-with-padding pointer">{classFilter.unitName} X</span>
              </button>
            )}
            <Select
              name="assigned_filter"
              isNotFullWidth
              onChange={(value) => {
                setSelectedAssignedFilter(value);
                onSelectedAssignedFilterChange(value);
              }}
              buttonClassName=" h-no-border "
              containerClassName="h-with-margin-left m-w-30 "
              options={[
                { content: 'Assigned users', value: 'assigned' },
                { content: 'Unassigned users', value: 'unassigned' },
              ]}
              defaultValue="assigned"
              value={selectedAssignedFilter}
              color="secondary"
            />

            <Select
              name="unit_list"
              onChange={(value) => onSelectedUnitChanged(value)}
              buttonClassName=" h-no-border "
              containerClassName="h-with-margin-left m-w-30 "
              displayTitle
              selectTitle="Filter by class"
              options={unitItems}
              isDisabled={Boolean(yearFilter) || Boolean(classFilter)}
              defaultValue={0}
              value={selectedUnit}
              color="secondary"
            />
          </div>
        </Form>
        <p
          className={cx({
            'h-with-2x-large-x-padding': isSmallerDevice,
          })}
        >
          Below you will see the names of students who have activated their accounts.
        </p>
        <div className="l-flex__item--column">
          {isUnassignedPopupVisible && (
            <RemoveUsersFromUnitPopup
              membershipIds={selectedUserIds}
              onClose={() => setIsUnassignedPopupVisible(false)}
              onCompleted={() => refreshStudents()}
            />
          )}
          <Table
            onPageSizeChange={onChangePageSize}
            isLoading={isTableLoading || isFetchMoreUsers}
            expandableClassName="h-background--color-grey-300 all-color-black"
            actions={[
              {
                buildHref: ({ selectedIds }) => ({
                  pathname: '/teacher/manage-students/assign-class',
                  query: { id: selectedIds },
                }),
                isDisabled({ selectedIds }) {
                  return selectedIds.length === 0;
                },
                title: 'Assign to class',
                buttonTooltipChildren: ({ selectedIds }) => {
                  return selectedIds.length ? (
                    <span>Click to assign to a class or move between class groups</span>
                  ) : (
                    <span>Select the student(s) you wish to assign class</span>
                  );
                },
                isDisplayButtonTooltip: true,
              },
              {
                title: 'Unassign students',
                onClick({ selectedIds }) {
                  setIsUnassignedPopupVisible(true);
                  let ids = selectedIds.map((str) => parseInt(str, 10));

                  setSelectedUserIds(ids);
                },
                isDisabled({ selectedIds }) {
                  return selectedIds.length === 0;
                },
                buttonTooltipChildren: ({ selectedIds }) => {
                  return selectedIds.length ? (
                    <span>Click to unassign the students</span>
                  ) : (
                    <span>Select the student(s) you wish to unassign</span>
                  );
                },
                isDisplayButtonTooltip: true,
              },
            ]}
            columns={[
              {
                isSortable: true,
                title: 'Students',
                width: 20,
              },
              {
                isSortable: true,
                priority: 1,
                title: 'Class',
                width: 7,
              },
              {
                isSortable: true,
                priority: 1,
                title: 'Year',
                width: 7,
              },
              {
                justify: 'start',
                priority: 3,
                title: 'Leader',
                width: 5,
              },
              {
                priority: 3,
                title: 'Email',
                width: 25,
              },
            ]}
            data={rows}
            itemName="Student"
            selectable
            isInformationVisible
            informationMessage="Select the student you wish to assign class"
          />
        </div>
        <div className="h-flex-display l-flex--justify-space-between">
          <Button
            className={isSmallerDevice ? 'h-with-margin' : 'h-with-y-margin'}
            isDisabled={!totalCount || totalCount === rows.length}
            isLoading={isGetGroupUsersLoading || isFetchMoreUsers}
            rotateIcon="270"
            onClick={onClickFetchMore}
          >
            View more
          </Button>
          {totalCount && (
            <p className="float-right bold h-color--brand l-vertical-centered">
              displaying {rows.length} of {totalCount}
            </p>
          )}
        </div>
      </section>
    </>
  );
};

export { ManageStudents };
