// const BASE_URL_AWS = 'https://ourfutures-public-assets.s3.ap-southeast-2.amazonaws.com';
const BASE_URL = `https://ekardo.s3-ap-southeast-2.amazonaws.com/production/project/${process.env.REACT_APP_PROJECT_ID}/Document/public`;

const health4lifeModuleUrl = 'health4life';

export const activityStudentPdfMap = {
  [health4lifeModuleUrl]: `${BASE_URL}/${health4lifeModuleUrl}`,
  // TODO: Comment in the following line when the activity pdf's are ready
  // [mentalHealthModuleUrl]: `${BASE_URL}/${mentalHealthModuleUrl}`,
};
